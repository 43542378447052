
.tile-wrapper{
  max-width: 240px;
}

@media(max-width: 767px) {
  .tile-wrapper{
    max-width: 190px
  }

}

